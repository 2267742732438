import React, { useState } from 'react'
import styles from './styles.module.scss'

import { ReactComponent as MainLogo } from '@icons/main_logo.svg'
import { ReactComponent as BurgerIcon } from '@icons/burger.svg'
import { Language } from '@components/Language'
import { Music } from '@components/Music'
import { useAppSelector } from '@app/redux/hooks'
import { useWindowSize } from '@lib/hooks/useWindowSize'
import { Burger } from './components/Burger'

export const Header = () => {
  const type = useAppSelector((state) => state.landing.type)
  const [burgerOpened, setBurgerOpened] = useState(false)
  const { isDesktop } = useWindowSize()

  const openBurger = () => {
    setBurgerOpened(true)
  }

  const closeBurger = () => {
    setBurgerOpened(false)
  }

  const logoClass = type === 'development' ? styles.logo_development : styles.logo
  const burgerClass = type === 'development' ? styles.burger_development : styles.burger

  return (
    <header className={styles.header}>
      <a href='/' className={logoClass}>
        <MainLogo />
      </a>

      <nav className={styles.nav}>
        <ul className={styles.nav_list}>
          {isDesktop && (
            <>
              <li className={styles.nav_item}>
                <Music />
              </li>
              <li className={styles.nav_item}>
                <Language />
              </li>
            </>
          )}
          <li className={styles.nav_item}>
            <button className={styles.nav_btn} onClick={openBurger}>
              <BurgerIcon className={burgerClass} />
            </button>
          </li>
        </ul>
      </nav>

      <Burger isOpened={burgerOpened} closeModal={closeBurger} />
    </header>
  )
}
