export interface IListItem {
  title: string
  soon: boolean
}

export const designList: IListItem[] = [
  { title: 'UX/UI', soon: false },
  { title: 'motion_design', soon: false },
  { title: 'graphic_design', soon: false },
  { title: 'NFT', soon: false },
  { title: 'commercials', soon: true },
]

export const developmentList: IListItem[] = [
  { title: 'blockchain_projects', soon: false },
  { title: 'computer_vision', soon: false },
  { title: 'outstaffing', soon: false },
  { title: 'cybersecurity', soon: false },
  { title: 'mobile_applications', soon: false },
  { title: 'administration', soon: false },
]

export const marketingList: IListItem[] = [{ title: 'soon', soon: false }]
