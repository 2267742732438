import React, { useEffect, useState } from 'react'
import cn from 'classnames'

import { Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { Home } from '@pages/Home'
import { Header } from '@components/Header'
import { useAppSelector } from '@app/redux/hooks'
import { sleep } from '@helpers/sleep'
import { WordLoader } from '@components/WordLoader'
import styles from './style.module.scss'

const App = () => {
  const type = useAppSelector((state) => state.landing.type)
  const [isLoading, setIsLoading] = useState(true)

  const getClassname = () => {
    switch (type) {
      case 'development':
        return styles.main_development
      case 'design':
        return styles.main_design
      case 'marketing':
        return styles.main_marketing
      default:
        return styles.main
    }
  }

  useEffect(() => {
    sleep(3700).then(() => setIsLoading(false))
  }, [])

  return (
    <main className={getClassname()}>
      <ToastContainer />
      <div className={styles.design_video}>
        {type === 'design' && (
          <video src='https://vas033.github.io/test-PAGroup/7d6bb9e9491ae9b8b5a4.webm' loop autoPlay muted />
        )}
      </div>
      <WordLoader className={cn(styles.wordLoader, !isLoading && styles.fadeOut)} />
      <div className={styles.container}>
        <Header />
        <Switch>
          <Route path='/' exact component={Home} />
        </Switch>
      </div>
    </main>
  )
}

export default App
