import { createSlice } from '@reduxjs/toolkit'
// import { api } from '@app/api'

// EXAMPLE THUNK
// export const postUserEmail = createAsyncThunk('user/setUserEmail', async (data: { userId: string; email: string }) => {
//   const response = await api.post<apiTypes.UserData>(`user/${data.userId}/email/${data.email}`, {})
//   return response.data
// })

export type TType = '' | 'design' | 'development' | 'marketing'

export const landingSlice = createSlice({
  name: 'landing',
  initialState: {
    type: '' as TType,
  },
  reducers: {
    setType(state, { payload }) {
      state.type = payload
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  extraReducers: (builder) => {
    // EXAMPLE
    // builder.addCase(cancel2FA.fulfilled, (state, { payload }) => {
    //   console.log('cancel2FA', payload)
    // })
    // builder.addCase(cancel2FA.rejected, (state, { payload }) => {
    //   console.log('cancel2FA', payload)
    // })
  },
})

export const { setType } = landingSlice.actions

export default landingSlice.reducer
