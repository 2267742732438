import React, { useState } from 'react'
import styles from './styles.module.scss'
import { useAudio } from '@lib/hooks/useAudio'
import cn from 'classnames'
import { useAppSelector } from '@app/redux/hooks'
import { MUSIC_LINK } from '@app/links'

type TNames = 'one' | 'two' | 'three' | 'four' | 'five' | 'six' | 'seven' | 'eight' | 'nine'

export const Music = () => {
  const { playing, toggle } = useAudio(MUSIC_LINK)
  const [wasPlayed, setWasPlayed] = useState(false)
  const type = useAppSelector((state) => state.landing.type)

  const applyClass = (name: TNames) => {
    // Проверяем была ли музыка хоть раз включена:
    // ...если да, то смотрим на playing и от этого уже ставим класс (с анимацией или снижением на 2 пикселя)
    // ...если нет, то применится класс со статичной высотой (у каждого блока разная высота)
    return wasPlayed
      ? playing
        ? cn(getMainClass(), styles[name])
        : cn(getMainClass(), styles[`${name}_stopped`])
      : cn(getMainClass(), styles[`${name}_not_played`])
  }

  const getMainClass = () => {
    switch (type) {
      case 'development':
        return styles.line_development
      default:
        return styles.line
    }
  }

  const onToggle = () => {
    setWasPlayed(true)
    toggle()
  }

  return (
    <button className={styles.btn} onClick={onToggle}>
      <div className={applyClass('one')} />
      <div className={applyClass('two')} />
      <div className={applyClass('three')} />
      <div className={applyClass('four')} />
      <div className={applyClass('five')} />
      <div className={applyClass('six')} />
      <div className={applyClass('seven')} />
      <div className={applyClass('eight')} />
      <div className={applyClass('nine')} />
    </button>
  )
}
