import { useRef, useEffect, useState } from 'react'
const VOLUME = 0.01

export const useAudio = (src: string) => {
  const audio = useRef(new Audio(src))
  audio.current.volume = VOLUME
  const [playing, setPlaying] = useState<boolean>(false)

  const toggle = () => setPlaying(!playing)

  useEffect(() => {
    playing ? audio.current.play() : audio.current.pause()
  }, [playing])

  return { playing, toggle }
}
