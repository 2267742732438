import React from 'react'

import { useAppSelector } from '@app/redux/hooks'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'

export const Subtitle = () => {
  const { t } = useTranslation()
  const type = useAppSelector((state) => state.landing.type)
  const subtitleClass = type === '' ? styles.subtitle : styles.subtitle_hide

  return <p className={subtitleClass}>{t('short_description')}</p>
}
