import React from 'react'
import cn from 'classnames'

import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'

export const WordLoader = ({ className }) => {
  const { t } = useTranslation()
  const phrase = [t('turn'), t('ideas'), t('into_working'), t('business')]

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.item}>
        <div className={styles.phrase}>
          <div className={styles.turning}>{phrase[0]}</div>
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.phrase}>
          <div className={styles.ideas}>{phrase[1]}</div>
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.phrase}>
          <div className={styles.into_working}>{phrase[2]}</div>
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.phrase}>
          <div className={styles.business}>{phrase[3]}</div>
        </div>
      </div>
    </div>
  )
}
