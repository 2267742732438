import React, { FC } from 'react'
import styles from './styles.module.scss'

import cn from 'classnames'
import { ReactComponent as AccordionIcon } from '@icons/accordion_arrow.svg'

interface TitleProps {
  title: string
  isOpened: boolean
}

export const Title: FC<TitleProps> = ({ title, isOpened }) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{title}</h1>
      <AccordionIcon className={cn(styles.arrow, isOpened && styles.arrow_active)} />
    </div>
  )
}
