import React from 'react'

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
    isDesktop: true,
  })

  React.useEffect(() => {
    const handleResize = () => {
      const currentWidth = document.documentElement.clientWidth
      const isDesktop = currentWidth >= 769
      setWindowSize({ width: currentWidth, height: document.documentElement.clientHeight, isDesktop })
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return windowSize
}
