import React, { FC } from 'react'
import cn from 'classnames'

import { IListItem, designList, developmentList, marketingList } from '@pages/Home/info'
import { TType } from '@app/redux/landingSlice'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'

interface ListProps {
  isListActive?: boolean // only for desktop
  isMobile?: boolean
  itemType: TType
  className?: string
}

export const List: FC<ListProps> = ({ isListActive, itemType, isMobile, className }) => {
  const { t } = useTranslation()
  const getItemClassname = () => {
    switch (itemType) {
      case 'design':
        return styles.item_design
      case 'marketing':
        return styles.item_marketing
      case 'development':
        return styles.item_development
      default:
        return styles.item
    }
  }

  const getDataViaType = () => {
    switch (itemType) {
      case 'design':
        return designList
      case 'marketing':
        return marketingList
      case 'development':
        return developmentList
      default:
        return []
    }
  }

  const getListClassViaIsActive = () => {
    return isListActive ? styles.list_show : styles.list
  }

  const listClassname = isMobile ? styles.list_mobile : getListClassViaIsActive()
  const itemClassname = isMobile ? styles.item_mobile : getItemClassname()
  const soonClassname = isMobile ? styles.soon_mobile : styles.soon

  return (
    <ul className={cn(listClassname, className)}>
      {getDataViaType().map((item: IListItem, idx) => (
        <li key={idx} className={itemClassname}>
          {t(item.title)}
          {item.soon && <span className={soonClassname}>{t('soon')}</span>}
        </li>
      ))}
    </ul>
  )
}
