import React, { FC } from 'react'
import styles from './styles.module.scss'

import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '@app/redux/hooks'

interface LanguageProps {
  className?: string
}

const LANG = {
  ru: 'en',
  en: 'ru',
}

export const Language: FC<LanguageProps> = ({ className }) => {
  const { i18n } = useTranslation()
  const type = useAppSelector((state) => state.landing.type)

  const buttonClass = type === 'development' ? styles.main_btn_development : styles.main_btn

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang)
  }

  const currentLang = i18n.language.split('-')[0].toLowerCase()

  return (
    <div className={cn(styles.container, className)}>
      <button className={buttonClass} onClick={() => changeLanguage(LANG[currentLang])}>
        {LANG[currentLang]}
      </button>
    </div>
  )
}
