import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'

import ReactDOM from 'react-dom'
import cn from 'classnames'
import { ReactComponent as CloseIcon } from '@icons/modal_cross.svg'

interface IModalProps {
  active: boolean
  setActive: (arg0: boolean) => void
  children: React.ReactNode

  crossFlag?: boolean
  isMobile?: boolean
  isDesktop?: boolean
  isCentered?: boolean
}

export const Modal = ({
  active,
  setActive,
  children,

  crossFlag,
  isDesktop,
  isCentered,
}: IModalProps) => {
  const node = document.getElementById('root')
  const bodyEl = useRef(document.querySelector('body'))
  const htmlEl = useRef(document.querySelector('html'))

  const modalClass = active ? styles.modal_active : styles.modal
  const centeredClass = isCentered && styles.centered
  const desktopClass = isDesktop && styles.desktopModal

  const [scrollPosition, setScrollPosition] = useState(0)
  const [renderCount, setRenderCount] = useState(0)

  useEffect(() => {
    if (renderCount > 0) {
      setScrollPosition(window.pageYOffset)
      const documentWidth = document.documentElement.clientWidth
      const windowWidth = window.innerWidth
      const scrollBarWidth = windowWidth - documentWidth
      bodyEl.current.style.paddingRight = scrollBarWidth.toString()

      if (active) {
        setScrollPosition(window.pageYOffset)
        bodyEl.current.style.top = `-${window.pageYOffset}px`
        bodyEl.current.style.overflow = 'hidden'
        bodyEl.current.style.position = 'fixed'
        htmlEl.current.style.height = '100vh'
      } else {
        bodyEl.current.style.removeProperty('overflow')
        bodyEl.current.style.removeProperty('position')
        bodyEl.current.style.removeProperty('top')
        htmlEl.current.style.removeProperty('height')
        window.scrollTo(0, scrollPosition)
      }

      return () => {
        bodyEl.current.style.removeProperty('overflow')
        bodyEl.current.style.removeProperty('position')
        bodyEl.current.style.removeProperty('top')
        htmlEl.current.style.removeProperty('height')
      }
    }
    setRenderCount((prev) => prev + 1)
  }, [active])

  const handleModalOutClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    setActive(false)
  }

  const handleClose = () => setActive(false)

  const handleModalContentClick = (event: SyntheticEvent) => event.stopPropagation()

  return ReactDOM.createPortal(
    <div className={cn(modalClass, centeredClass, desktopClass)} onClick={handleModalOutClick}>
      <div className={styles.modal_content} onClick={handleModalContentClick}>
        <div className={styles.inner}>
          {children}
          {crossFlag && (
            <div className={styles.cross_container}>
              <button type='button' className={styles.cross_button} onClick={handleClose}>
                <CloseIcon />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>,
    node,
  )
}
