import React, { FC } from 'react'

import { useAppSelector } from '@app/redux/hooks'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Arrow } from '@icons/arrow.svg'
import { BID_LINK } from '@app/links'
import styles from './styles.module.scss'

interface BidProps {
  isMobile?: boolean
}

export const Bid: FC<BidProps> = ({ isMobile }) => {
  const { t } = useTranslation()
  const type = useAppSelector((state) => state.landing.type)
  const bidClass = type === '' ? styles.bid : styles.bid_hide

  return (
    <a href={BID_LINK} target='_blank' className={bidClass} rel='noreferrer'>
      <div className={styles.bid_arrow}>
        <Arrow />
      </div>
      <span className={styles.bid_text}>
        {t('submit')} {!isMobile && <br />} {t('application')}
      </span>
    </a>
  )
}
