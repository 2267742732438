import React from 'react'

import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { useTranslation } from 'react-i18next'
import { setType } from '@app/redux/landingSlice'
import { List } from '../List'
import { Bid } from '../Bid'
import { Subtitle } from '../Subtitle'
import styles from './styles.module.scss'
import { OTHER_SITES_LINKS } from '@app/links'

export const HomeDesktop = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const type = useAppSelector((state) => state.landing.type)

  const setDevelopment = () => {
    dispatch(setType('development'))
  }

  const setDesign = () => {
    dispatch(setType('design'))
  }

  const setMarketing = () => {
    dispatch(setType('marketing'))
  }

  const setInitial = () => {
    dispatch(setType(''))
  }

  const dotClass = type === '' ? styles.dot : styles.dot_hide

  const getDefaultOrHideTitleClass = () => {
    return type === '' ? styles.title : styles.title_hide
  }

  const designTitleClass = type === 'design' ? styles.title_design : getDefaultOrHideTitleClass()
  const developmentTitleClass = type === 'development' ? styles.title_development : getDefaultOrHideTitleClass()
  const marketingTitleClass = type === 'marketing' ? styles.title_marketing : getDefaultOrHideTitleClass()

  return (
    <div className={styles.container}>
      <div className={styles.titles}>
        <a
          href={OTHER_SITES_LINKS.design}
          target='_blank'
          rel='noreferrer'
          className={designTitleClass}
          onMouseEnter={setDesign}
          onMouseLeave={setInitial}
        >
          {t('design')}
          <List isListActive={type === 'design'} itemType='design' />
        </a>
        <span className={dotClass}>,&nbsp;</span>

        <a
          href={OTHER_SITES_LINKS.development}
          target='_blank'
          rel='noreferrer'
          className={developmentTitleClass}
          onMouseEnter={setDevelopment}
          onMouseLeave={setInitial}
        >
          {t('development')}
          <List isListActive={type === 'development'} itemType='development' />
        </a>

        <span className={dotClass}>,&nbsp;</span>
        {/* TODO: появится ссылка - заменить на <a ...> */}
        <h1 className={marketingTitleClass} onMouseEnter={setMarketing} onMouseLeave={setInitial}>
          {t('marketing')}
          <List isListActive={type === 'marketing'} itemType='marketing' />
        </h1>
      </div>

      <Subtitle />
      <Bid />
    </div>
  )
}
