import React, { FC } from 'react'
import styles from './styles.module.scss'

import { useWindowSize } from '@lib/hooks/useWindowSize'
import { BID_LINK, MAIL, OTHER_SITES_LINKS } from '@app/links'
import { Modal } from '@components/Modal'
import { Language } from '@components/Language'
import { Item } from '../Item'
import { useTranslation } from 'react-i18next'

interface BurgerProps {
  isOpened: boolean
  closeModal: () => void
}

export const Burger: FC<BurgerProps> = ({ isOpened, closeModal }) => {
  const { t } = useTranslation()
  const { isDesktop } = useWindowSize()

  return (
    <Modal active={isOpened} setActive={closeModal} isDesktop crossFlag>
      <div className={styles.container}>
        {!isDesktop && (
          <div className={styles.language}>
            <Language />
          </div>
        )}
        <div className={styles.left}>
          <div className={styles.texts}>
            <p className={styles.text}>{t('description_one')}</p>
            <p className={styles.text}>{t('description_two')}</p>
          </div>

          <div className={styles.mails}>
            <p className={styles.waiting}>{t('waiting_letter')}</p>
            <a href={`mailto:${MAIL}`} target='_blank' className={styles.mail} rel='noreferrer'>
              {MAIL}
            </a>
          </div>
        </div>

        <div className={styles.right}>
          <ul className={styles.list}>
            <Item
              className={styles.item}
              link={OTHER_SITES_LINKS.design}
              title={t('creative_team')}
              subtitle={t('beauty_department')}
            />
            <Item
              className={styles.item}
              link={OTHER_SITES_LINKS.development}
              title={t('development_team')}
              subtitle={t('projects_department')}
            />
            <Item
              className={styles.item}
              link={OTHER_SITES_LINKS.marketing}
              title={t('marketing_team')}
              subtitle={t('sales_department')}
              isSoon
            />
            <Item className={styles.item} link={OTHER_SITES_LINKS.projects} title={t('projects')} subtitle='' />
            <Item
              className={styles.item}
              link={OTHER_SITES_LINKS.design}
              title={t('work_with_us')}
              subtitle={t('in_progress')}
              isSoon
            />
          </ul>

          <div className={styles.buttons}>
            <a href={BID_LINK} target='_blank' className={styles.button} rel='noreferrer'>
              {t('submit')} {t('application')}
            </a>
            {/* <a href={PRESENTATION_LINK} target='_blank' className={styles.button} rel='noreferrer'>
              {t('presentation')}
            </a> */}
          </div>
        </div>
      </div>
    </Modal>
  )
}
