export const OTHER_SITES_LINKS = {
  development: 'https://dev.antipoff.iamrobot.xyz/',
  design: 'https://design.antipoff.iamrobot.xyz/',
  marketing: 'https://ru.wikipedia.org/wiki/%D0%9C%D0%B0%D1%80%D0%BA%D0%B5%D1%82%D0%B8%D0%BD%D0%B3',
  projects: 'https://www.behance.net/antipoff_group',
}

export const BID_LINK = 'https://pa-sales.bitrix24.site/'
export const MUSIC_LINK = './Taxi_-_Soul.mp3'
export const PRESENTATION_LINK =
  'https://ru.wikipedia.org/wiki/%D0%9F%D1%80%D0%B5%D0%B7%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D1%8F_(%D1%81%D0%BF%D0%BE%D1%81%D0%BE%D0%B1_%D0%BF%D1%80%D0%B5%D0%B4%D1%81%D1%82%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8)'
export const MAIL = 'pavelantipov39@gmail.com'
