import React, { ReactElement, ReactNode } from 'react'
import classNames from 'classnames/bind'
import cn from 'classnames'
import styles from './styles.module.scss'

type ButtonType = 'primary-blue' | 'secondary-blue' | 'gray'

interface ButtonProps {
  type: ButtonType
  children: ReactNode
  buttonType?: 'button' | 'submit'
  fullWidth?: boolean
  disabled?: boolean
  onClick?: () => void
  className?: string
}

export const Button: React.FC<ButtonProps> = ({
  type,
  children,
  fullWidth = true,
  disabled = false,
  onClick,
  buttonType = 'button',
  className,
}: ButtonProps): ReactElement => {
  const cx = classNames.bind(styles)
  const buttonClassname = cx(
    'button',
    `button-${type}`,
    { buttonFullWidth: fullWidth },
    { 'button-disabled': disabled },
  )

  const clickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (onClick) {
      event.preventDefault()
      event.stopPropagation()
      onClick()
    }
  }

  return (
    <button type={buttonType} className={cn(buttonClassname, className)} disabled={disabled} onClick={clickHandler}>
      {children}
    </button>
  )
}
