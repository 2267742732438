import React, { FC } from 'react'
import cn from 'classnames'

import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'

interface ItemProps {
  link: string
  title: string
  subtitle: string

  className?: string
  isSoon?: boolean
  inDevelopment?: boolean
}

export const Item: FC<ItemProps> = ({ link, title, subtitle, className, isSoon = false, inDevelopment = false }) => {
  const { t } = useTranslation()
  const getContent = () => {
    if (isSoon) {
      return (
        <p className={styles.link_soon}>
          {title}
          <span className={styles.soon}>{t('soon')}</span>
        </p>
      )
    }

    if (inDevelopment) {
      return <p className={styles.link_inDevelopment}>{title}</p>
    }

    return (
      <a href={link} target='_blank' rel='noreferrer' className={styles.link}>
        {title}
      </a>
    )
  }

  return (
    <li className={cn(styles.item, className)}>
      {getContent()}
      <p className={styles.subtitle}>{subtitle}</p>
    </li>
  )
}
