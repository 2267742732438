import React, { useState } from 'react'
import styles from './styles.module.scss'

import { Button } from '@components/Button'
import { List } from '../List'
import { Title } from './components/Title'
import { Subtitle } from '../Subtitle'
import { Bid } from '../Bid'

import Collapsible from 'react-collapsible'
import { useTranslation } from 'react-i18next'

import { TType } from '@app/redux/landingSlice'
import { OTHER_SITES_LINKS } from '@app/links'

export const HomeMobile = () => {
  const { t } = useTranslation()
  const [opened, setOpened] = useState<TType>('')

  const setDesign = () => {
    setOpened('design')
  }

  const setDevelopment = () => {
    setOpened('development')
  }

  const setMarketing = () => {
    setOpened('marketing')
  }

  const setInitial = () => {
    setOpened('')
  }

  return (
    <div className={styles.container}>
      <div className={styles.accordions}>
        <div className={styles.accordion_wrapper}>
          <Collapsible
            onOpen={setDesign}
            onClose={setInitial}
            trigger={<Title title={t('design')} isOpened={opened === 'design'} />}
          >
            <List isMobile itemType='design' className={styles.list} />
            <Button type='primary-blue' className={styles.button}>
              <a href={OTHER_SITES_LINKS.design} target='_blank' className={styles.link} rel='noreferrer'>
                {t('website')}
              </a>
            </Button>
          </Collapsible>
        </div>

        <div className={styles.accordion_wrapper}>
          <Collapsible
            onOpen={setDevelopment}
            onClose={setInitial}
            trigger={<Title title={t('development')} isOpened={opened === 'development'} />}
          >
            <List isMobile itemType='development' className={styles.list} />
            <Button type='primary-blue' className={styles.button}>
              <a href={OTHER_SITES_LINKS.development} target='_blank' className={styles.link} rel='noreferrer'>
                {t('website')}
              </a>
            </Button>
          </Collapsible>
        </div>

        <div className={styles.accordion_wrapper}>
          <Collapsible
            onOpen={setMarketing}
            onClose={setInitial}
            trigger={<Title title={t('marketing')} isOpened={opened === 'marketing'} />}
          >
            <List isMobile itemType='marketing' className={styles.list} />
          </Collapsible>
        </div>
      </div>

      <Subtitle />
      <Bid isMobile />
    </div>
  )
}
